<template>
  <layout-vertical>
    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapGetters } from 'vuex'
import Navbar from '../components/Navbar.vue'

export default {
  components: {
    // AppCustomizer,
    LayoutVertical,
    Navbar,
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
    }
  },
  computed: {
    ...mapGetters(['isBlogArticlesLoading']),
    ...mapGetters('services', ['getRecurringServices', 'getRecurringServicesWithoutPagination']),
    ...mapGetters('customers', ['getCustomersWithoutPagination']),
    ...mapGetters('orders', [
      'getOrders',
      'getDirectOrders',
      'getNotCompletedDirectOrders',
      'getArchivedDirectOrders',
      'getTransactions',
      'getClientByPortefeuille',
    ]),
    ...mapGetters('professionals', [
      'getProfessionals',
      'getEmployees',
      'getPayedPros',
      'getUnpayedPros',
      'getActifEmployees',
    ]),
    ...mapGetters('packages', ['getDirectPackages']),
  },
  watch: {},
  async created() {
    const token = localStorage.getItem('token')
    const user = JSON.parse(localStorage.getItem('user'))
    this.$store.commit('auth/SET_USER', user)
    this.$store.commit('auth/SET_TOKEN', token)

    switch (user.role.slug) {
      case "super-administrateur":
        this.applyGetEmployeesAction();
        this.applyGetBusinessOrdersAction();
        this.applyGetUnformedEmployeesAction();
        this.applyGetBlogCategoriesAction();
        await this.applyGetBlogArticlesAction();
        // this.applyGetDirectOrdersActifAction();
        this.applyGetDirectPackagesAction();
        this.applyGetCustomersWithoutPaginationAction();
        this.applyEmployeesWithoutPaginationAction();
        this.applyGetServicesRecurringWithoutPaginationAction();
        //this.applyGetDirectPackageOrdersAction();
        this.applyGetTransactionsAction();
        this.applyGetClientByPortefeuilleAction();
        this.applyGetActivityAction();
        this.applyGetCustomersMeetAction();
        this.applyGetArchivedDirectOrdersAction();
        this.applyGetArchivedBusinessOrdersAction();
        this.applyGetCustomersWithoutPaginationAction();
        this.applyGetEmployeesWalletWallet();
        this.applyEmployeesWithoutPaginationAction();
        this.applyGetCustomersAction();
        
        
        
        this.applyGetServicesAction();
        // this.applyGetDirectPackageOrdersAction();
        this.applyGetTransactionsAction()
        this.applyGetClientByPortefeuilleAction()
        this.applyGetActivityAction()
        this.applyGetCustomersMeetAction()
        this.applyGetArchivedDirectOrdersAction()
        this.applyGetArchivedBusinessOrdersAction()
        this.applyGetCustomersWithoutPaginationAction()
        this.applyGetEmployeesWalletWallet()
        this.applyEmployeesWithoutPaginationAction()
        this.applyGetCustomersAction()
        this.applyGetBusinessOrdersAction()

        this.applyGetServicesAction()
        // this.applyGetDirectPackageOrdersAction();
        this.applyGetRecurringServicesAction()
        this.applyEmployeesWithoutPaginationAction()
        this.applyGetServicesWithoutPaginationAction()
        // await this.getActifEmployeesAction();

        this.applyGetOrdersAction()
        this.applyGetProfessionalsAction()
        this.applyProfessionalsWithoutPaginationAction()
        this.applyGetBusinessManagersListAction()
        this.applyGetBusinessDeveloperListAction()
        this.applyGetARHListAction()
        this.applyGetPointsFocauxListAction()
        this.applyGetPointFocauxWithoutPagination()
        this.applyGetEmployeesWalletsLogsWallet()
        this.applyGetNotificationListAction()
        await this.getBestProfessionalsAction()
        this.applyGetMostRequestedServicesAction()
        // await this.getMostRequestedServicesAction();
        // await this.getMostRequestedRecurringServicesAction();
        this.applyGetMostRequestedRecurringServicesAction()
        await this.getClientByPortefeuilleAction()
        this.applyGetDateFinanceStatisticsAction()
        this.applyGetMonthFinanceStatisticsAction()
        this.applyGetNumbersStatisticsAction()
        this.applyGetTauxStatisticsAction()
        this.applyGetProPerServiceAndAdressAction()
        this.applyGetSatisfiedOrderAction()
        this.applyGetUnsatisfiedOrderAction()
        this.applyGetProAmountAction()
        this.applyGetCommissionAction()
        this.applyGetDirectOrdersSalaryPayments()
        this.applyGetBusinessOrdersSalaryPayments()
        this.applyGetDirectOrdersSalaryEmployees()
        this.applyGetCustomerConversionAction()
        // this.applyGetProAmountPerServiceAction();
        this.applyGetCanceledOrdersAction()

        // this.applyGetDirectOrderAction();

        // this.applyGetPayedProsAction();
        // this.applyGetUnpayedProsAction();
        // this.applyGetNotCompletedDirectOrdersAction();
        // this.applyGetCustomersInDeptAction();

        // this.applyGetDirectOrdersWhoNeedRecommandationAction();
        
        
        break;
      case "responsable-commercial":
        this.applyGetDirectPackagesAction();
        this.applyGetBusinessOrdersAction();
        // this.applyGetDirectPackageOrdersAction();
        this.applyGetDirectOrdersSalaryPayments()
        this.applyGetBusinessOrdersSalaryPayments()
        this.applyGetDateFinanceStatisticsAction()
        this.applyGetBlogCategoriesAction()
        await this.applyGetBlogArticlesAction()
        this.applyGetMonthFinanceStatisticsAction()
        this.applyGetNumbersStatisticsAction()
        this.applyGetOrdersAction()
        this.applyGetCustomersAction()
        this.applyGetServicesRecurringWithoutPaginationAction()
        this.applyGetCustomersWithoutPaginationAction()
        this.applyEmployeesWithoutPaginationAction()
        this.applyGetTauxStatisticsRCAction()
        this.applyGetNumbersStatisticsAction()
        this.applyGetRCStatisticsAction()
        this.applyGetActivityAction()
        this.applyGetCustomersMeetAction()
        //  await this.getMostRequestedRecurringServicesAction();
        this.applyGetMostRequestedRecurringServicesAction()
        this.applyGetArchivedDirectOrdersAction()
        this.applyGetArchivedBusinessOrdersAction()
        this.applyGetServicesWithoutPaginationAction()
        this.applyGetNotificationListAction()
        await this.getBestProfessionalsAction()
        this.applyGetMostRequestedServicesAction()
        // await this.getMostRequestedServicesAction();
        this.applyGetRecurringServicesAction()
        this.applyGetCanceledOrdersAction()
        /* this.applyGetDirectOrderAction(); */
        this.applyGetNotCompletedDirectOrdersAction();
        this.applyGetBusinessManagersListAction();
        this.applyGetDirectOrdersActifAction();
        break;
      case "responsable-relation-client":
        this.applyGetDirectPackagesAction();
        this.applyGetBusinessOrdersAction();
        // this.applyGetDirectPackageOrdersAction();
        this.applyGetDirectOrdersSalaryPayments();
        this.applyGetBusinessOrdersSalaryPayments();
        this.applyGetDateFinanceStatisticsAction();
        this.applyGetBlogCategoriesAction();
        await this.applyGetBlogArticlesAction();
        this.applyGetMonthFinanceStatisticsAction();
        this.applyGetNumbersStatisticsAction();
        this.applyGetOrdersAction();
        this.applyGetCustomersAction();
        this.applyGetServicesRecurringWithoutPaginationAction();
        this.applyGetCustomersWithoutPaginationAction();
        this.applyEmployeesWithoutPaginationAction();
        this.applyGetTauxStatisticsRCAction();
        this.applyGetNumbersStatisticsAction();
        this.applyGetRCStatisticsAction();
        this.applyGetActivityAction();
        this.applyGetCustomersMeetAction();
        //  await this.getMostRequestedRecurringServicesAction();
        this.applyGetMostRequestedRecurringServicesAction();
        this.applyGetArchivedDirectOrdersAction();
        this.applyGetArchivedBusinessOrdersAction();
        this.applyGetServicesWithoutPaginationAction();
        this.applyGetNotificationListAction();
        await this.getBestProfessionalsAction();
        this.applyGetMostRequestedServicesAction();
        // await this.getMostRequestedServicesAction(); 
        this.applyGetRecurringServicesAction();
        this.applyGetCanceledOrdersAction();
        /* this.applyGetDirectOrderAction(); */
        this.applyGetNotCompletedDirectOrdersAction();
        this.applyGetBusinessManagersListAction();
        this.applyGetDirectOrdersActifAction();
        break;
        
      case "charge-daffaires":
        this.applyGetBusinessOrdersAction();
        this.applyGetDirectPackagesAction();
        this.applyGetCustomersWithoutPaginationAction();
        this.applyEmployeesWithoutPaginationAction();
        this.applyGetServicesWithoutPaginationAction();
        this.applyGetServicesRecurringWithoutPaginationAction();
        // this.applyGetDirectPackageOrdersAction();
        this.applyGetRecurringServicesAction()
        /* this.applyGetDirectOrderAction(); */
        this.applyGetDirectOrdersSalaryPayments()
        this.applyGetBusinessOrdersSalaryPayments()
        this.applyGetCustomersAction()
        this.applyGetArchivedDirectOrdersAction()
        this.applyGetArchivedBusinessOrdersAction()
        this.applyGetCustomersMeetAction()
        this.applyGetActivityAction()

        this.applyGetTauxStatisticsCAAction()
        this.applyGetCAStatisticsAction()
        this.applyGetNumbersStatisticsAction()

        //  await this.getMostRequestedRecurringServicesAction();
        this.applyGetMostRequestedRecurringServicesAction()

        // this.applyGetDirectOrdersActifAction();
        await this.getBestProfessionalsAction()
        this.applyGetMostRequestedServicesAction()
        // await this.getMostRequestedServicesAction();
        this.applyGetNotCompletedDirectOrdersAction()
        this.applyGetCustomersInDeptAction()
        break
      case 'rh':
        this.applyGetBusinessOrdersAction()
        this.applyGetDirectPackagesAction()
        this.applyGetServicesRecurringWithoutPaginationAction()
        this.applyGetCustomersWithoutPaginationAction()
        this.applyEmployeesWithoutPaginationAction()
        this.applyGetServicesWithoutPaginationAction()
        this.applyGetEmployeesAction()
        this.applyGetDirectOrdersSalaryEmployees()
        this.applyGetMostRequestedServicesAction()
        this.applyGetPointFocauxWithoutPagination()
        this.applyGetRHStatisticsAction()
        this.applyGetARHListAction()
        // this.applyGetDirectPackageOrdersAction();
        /* this.applyGetDirectOrderAction();  */
        /* this.applyGetOrdersAction(); */
        this.applyGetCustomersAction()

        this.applyGetNumbersStatisticsAction()
        this.applyGetTauxStatisticsRHAction()
        this.applyGetPointsFocauxListAction()
        this.applyGetCustomersMeetAction()
        this.applyGetProfessionalsAction()
        // await this.getMostRequestedServicesAction();
        // await this.getMostRequestedRecurringServicesAction();
        this.applyGetMostRequestedRecurringServicesAction()
        // await this.getActifEmployeesAction();
        this.applyGetRecurringServicesAction()
        this.applyGetBusinessManagersListAction()
        /* this.applyGetDirectOrdersActifAction(); */
        break
      case 'assistant-rh':
        this.applyGetBusinessOrdersAction()
        this.applyGetDirectPackagesAction()
        // this.applyGetDirectPackageOrdersAction();
        this.applyGetOrdersAction()
        this.applyGetCustomersAction()
        this.applyGetServicesRecurringWithoutPaginationAction()
        this.applyGetCustomersWithoutPaginationAction()
        this.applyEmployeesWithoutPaginationAction()
        this.applyGetServicesWithoutPaginationAction()
        this.applyGetEmployeesAction()
        this.applyGetMostRequestedServicesAction()
        this.applyGetPointFocauxWithoutPagination()
        this.applyGetARHStatisticsAction()
        this.applyGetTauxStatisticsARHAction()
        this.applyGetPointsFocauxListAction()
        this.applyGetCustomersMeetAction()
        // await this.getActifEmployeesAction();
        // await this.getMostRequestedServicesAction();
        // await this.getMostRequestedRecurringServicesAction();
        this.applyGetMostRequestedRecurringServicesAction()
        this.applyGetRecurringServicesAction()
        /* this.applyGetDirectOrderAction(); */
        this.applyGetBusinessManagersListAction();
        this.applyGetDirectOrdersActifAction();
        break;
      case "charge-de-clientele":
        this.applyGetDirectPackagesAction();
        // this.applyGetDirectPackageOrdersAction();
        this.applyGetOrdersAction();
        this.applyGetBusinessOrdersAction();
        this.applyGetTauxStatisticsCCAction();
        this.applyGetCustomersAction();
        this.applyGetCustomersWithoutPaginationAction();
        this.applyEmployeesWithoutPaginationAction();
        this.applyGetServicesWithoutPaginationAction();
        this.applyGetNotificationListAction();
        /* this.applyGetDirectOrderAction(); */
        
        await this.getBestProfessionalsAction();
        this.applyGetRecurringServicesAction();
        this.applyGetMostRequestedServicesAction();
        this.applyGetCCStatisticsAction()
        // await this.getMostRequestedServicesAction();
        // await this.getMostRequestedRecurringServicesAction();
        this.applyGetMostRequestedRecurringServicesAction()
        this.applyGetNotCompletedDirectOrdersAction()
        this.applyGetCanceledOrdersAction()
        this.applyGetEmployeesWalletsLogsWallet()
        this.applyGetServicesRecurringWithoutPaginationAction()
        break
      case 'formateur':
        this.applyGetUnformedEmployeesAction()
        await this.getBestProfessionalsAction()
        this.applyGetMostRequestedServicesAction()
        // await this.getMostRequestedServicesAction();
        // await this.getMostRequestedRecurringServicesAction();
        this.applyGetMostRequestedRecurringServicesAction()
        this.applyGetServicesAction()
        this.applyGetPointsFocauxListAction()
        this.applyGetPointFocauxWithoutPagination()
        this.applyGetDirectOrdersWhoNeedRecommandationAction()
        this.applyGetProfessionalsAction()
        this.applyGetCustomersWithoutPaginationAction()
        this.applyEmployeesWithoutPaginationAction()
        this.applyEmployeesWithoutPaginationAction()
        await this.getBestProfessionalsAction()
        this.applyGetMostRequestedServicesAction()
        // await this.getMostRequestedServicesAction();
        // await this.getMostRequestedRecurringServicesAction();
        this.applyGetMostRequestedRecurringServicesAction();
        this.applyGetProPerServiceAndAdressAction();
        this.applyGetProAmountPerServiceAction();
        this.applyGetRecurringServicesAction();
        this.applyProfessionalsWithoutPaginationAction();
        this.applyGetEmployeesAction();
        this.applyGetPayedProsAction();
        this.applyGetUnpayedProsAction();
        this.applyGetEmployeesWalletWallet();
        this.applyGetServicesRecurringWithoutPaginationAction();
        break;
      case "community-manager":
        this.applyGetBlogCategoriesAction();
        this.applyGetBlogArticlesAction();
        break;

      case "superviseur":
        this.applyGetCustomersWithoutPaginationAction();
        this.applyEmployeesWithoutPaginationAction();
        break;
      case "business-developer-pro":
        this.applyGetUnformedEmployeesAction();
        this.applyGetServicesAction();
        this.applyGetDirectOrdersActifAction();
        this.applyGetPointsFocauxListAction();
        this.applyGetServicesWithoutPaginationAction();
        this.applyGetPointFocauxWithoutPagination();
        this.applyGetDirectOrdersWhoNeedRecommandationAction();
        this.applyGetProfessionalsAction();
        this.applyGetCustomersWithoutPaginationAction();
        this.applyEmployeesWithoutPaginationAction();
        this.applyEmployeesWithoutPaginationAction();
        await this.getBestProfessionalsAction();
        this.applyGetMostRequestedServicesAction();
        // await this.getMostRequestedServicesAction();
        // await this.getMostRequestedRecurringServicesAction();
        this.applyGetMostRequestedRecurringServicesAction();
        this.applyGetProPerServiceAndAdressAction();
        this.applyGetProAmountPerServiceAction();
        this.applyGetRecurringServicesAction();
        this.applyProfessionalsWithoutPaginationAction();
        this.applyGetEmployeesAction();
        this.applyGetPayedProsAction();
        this.applyGetUnpayedProsAction();
        this.applyGetEmployeesWalletWallet();
        this.applyGetServicesRecurringWithoutPaginationAction();
        case "comptable":
        this.applyGetCustomersWithoutPaginationAction();
        this.applyEmployeesWithoutPaginationAction();
        this.applyGetDirectOrdersSalaryPayments();
        this.applyGetBusinessOrdersSalaryPayments();
        this.applyGetDirectOrdersSalaryEmployees();
        this.applyGetDateFinanceStatisticsAction();
        this.applyGetMonthFinanceStatisticsAction();
        break;
        case "assistante-administrative":
        this.applyGetCustomersWithoutPaginationAction();
        this.applyEmployeesWithoutPaginationAction();
        this.applyGetDirectOrdersSalaryPayments();
        this.applyGetBusinessOrdersSalaryPayments();
        this.applyGetDirectOrdersSalaryEmployees();
        this.applyGetDateFinanceStatisticsAction();
        this.applyGetMonthFinanceStatisticsAction();
        this.applyGetEmployeesWalletWallet();
        break;
      default:
        break
    }
  },
  methods: {
    ...mapActions('packages', [
      'getDirectPackagesAction',
      'getDirectPackageOrdersAction',
    ]),
    ...mapActions('services', [
      'getServicesAction',
      'getMostRequestedServicesAction',
      'getRecurringServicesAction',
      'getMostRequestedRecurringServicesAction',
      'getServicesWithoutPaginationAction',
      'getRecurringServicesWithoutPaginationAction',
    ]),
    ...mapActions('blog', ['getAllCategoriesAction', 'getAllArticlesAction']),
    ...mapActions('orders', [
      'getOrdersAction',
      'getCanceledOrdersAction',
      'getDirectOrdersActifAction',
      'getDirectOrdersAction',
      'getBusinessOrdersAction',
      'getNotCompletedDirectOrdersAction',
      'getArchivedDirectOrdersAction',
      'getArchivedBusinessOrdersAction',
      'getDirectOrdersSalaryPaymentsAction',
      'getBusinessOrdersSalaryPaymentsAction',
      'getDirectOrdersSalaryEmployeesAction',
      'getDirectOrdersWhoNeedRecommandationAction',
      'getTransactionsAction',
      'getClientByPortefeuilleAction',
      'getCustomersMeetAction',
      'getActivityAction',
    ]),
    ...mapActions('formation', ['getUnformedEmployeesAction']),
    ...mapActions('professionals', [
      'getProfessionalsAction',
      'getEmployeesWalletAction',
      'getBestProfessionalsAction',
      'getProPerServiceAndAdressAction',
      'getEmployeesAction',
      'getPayedAndUnpayedEmployeesAction',
      'getProfessionalsWithoutPaginationAction',
      'getPayedProsAction',
      'getUnpayedProsAction',
      'getEmployeesWithoutPaginationAction',
      'getEmployeesWalletsLogsAction',
      'getActifEmployeesAction',
    ]),
    ...mapActions('customers', [
      'getCustomersAction',
      'getCustomersInDeptAction',
      'getCustomersWithoutPaginationAction',
      'getCustomerOrdersAction',
      'getCustomerDirectOrdersAction',
    ]),
    ...mapActions('statistics', [
      'getSatisfiedOrderAction',
      'getUnsatisfiedOrderAction',
      'getProAmountAction',
      'getCommissionAction',
      'getCustomerConversionAction',
      'getProAmountPerServiceAction',
      'getDateFinanceStatisticsAction',
      'getMonthFinanceStatisticsAction',
      'getNumbersStatisticsAction',
      'getRCStatisticsAction',
      'getCAStatisticsAction',
      'getCCStatisticsAction',
      'getRHStatisticsAction',
      'getARHStatisticsNumberAction',
      'getTauxStatisticsAction',
      'getTauxStatisticsRCAction',
      'getTauxStatisticsCAAction',
      'getTauxStatisticsCAByIdAction',
      'getTauxStatisticsARHByIdAction',
      'getTauxStatisticsCCAction',
      'getTauxStatisticsRHAction',
      'getTauxStatisticsARHAction',
      'getBusinessDeveloperStatisticsAction',
      'getARHStatisticsNumberAction',
      'getBusinessManagerStatisticsAction',
    ]),
    ...mapActions('roles', [
      'getBusinessManagersListAction',
      'getBusinessDeveloperListAction',
      'getAssistantRHListAction',
    ]),
    ...mapActions('offerType', [
      'getPointFocalAction',
      'getPointFocauxWithoutPaginationAction',
    ]),

    ...mapActions('users', ['getNotificationsAction']),

    applyGetServicesRecurringWithoutPaginationAction() {
      this.getRecurringServicesWithoutPaginationAction()
        .then(() => {
          this.$store.commit(
            'SET_IS_SERVICES_RECURRING_WITHOUT_PAGINATION_LOADING',
            false,
          )
        })
        .catch(() => {
          this.$store.commit(
            'SET_IS_SERVICES_RECURRING_WITHOUT_PAGINATION_LOADING',
            false,
          )
        })
    },
    //  applyGetActifEmployees() {
    //   this.getActifEmployeesAction()
    //     .then(() => {
    //       this.$store.commit(
    //         "SET_IS_GET_ACTIF_EMPLOYEES_LOADING",
    //         false
    //       );
    //     })
    //     .catch(() => {
    //       this.$store.commit(
    //         "SET_IS_GET_ACTIF_EMPLOYEES_LOADING",
    //         false
    //       );
    //     });
    // },
    applyGetBlogCategoriesAction() {
      this.getAllCategoriesAction()
        .then(() => {
          this.$store.commit('SET_IS_BLOGS_CATEGORIES_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_BLOGS_CATEGORIES_LOADING', false)
        })
    },
    applyGetBlogArticlesAction() {
      this.getAllArticlesAction()
        .then(() => {
          this.$store.commit('SET_IS_BLOGS_ARTICLES_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_BLOGS_ARTICLES_LOADING', false)
        })
    },
    applyGetDirectOrdersSalaryPayments() {
      this.getDirectOrdersSalaryPaymentsAction()
        .then(() => {
          applyGetDirectOrdersActifAction
          this.$store.commit('SET_IS_PAYMENT_HISTORY_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_PAYMENT_HISTORY_LOADING', false)
        })
    },
    applyGetBusinessOrdersSalaryPayments() {
      this.getBusinessOrdersSalaryPaymentsAction()
        .then(() => {
          applyGetDirectOrdersActifAction
          this.$store.commit('SET_IS_BUSINESS_PAYMENT_HISTORY_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_BUSINESS_PAYMENT_HISTORY_LOADING', false)
        })
    },
    applyGetDirectOrdersSalaryEmployees() {
      this.getDirectOrdersSalaryEmployeesAction()
        .then(() => {
          this.$store.commit('SET_IS_PAYMENT_EMPLOYEES_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_PAYMENT_EMPLOYEES_LOADING', false)
        })
    },
    applyGetPointFocauxWithoutPagination() {
      this.getPointFocauxWithoutPaginationAction()
        .then(() => {
          this.$store.commit('SET_IS_POINT_FOCAUX_WITHOUT_PAGINATION_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_POINT_FOCAUX_WITHOUT_PAGINATION_LOADING', false)
        })
    },

    applyGetDirectOrdersActifAction() {
      this.getDirectOrdersActifAction()
        .then(() => {
          this.$store.commit('SET_IS_DIRECT_ORDERS_ACTIF_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_DIRECT_ORDERS_ACTIF_LOADING', false)
        })
    },
    applyGetPointsFocauxListAction() {
      this.getPointFocalAction()
        .then(() => {
          this.$store.commit('SET_IS_POINTS_FOCAUX_LIST_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_POINTS_FOCAUX_LIST_LOADING', false)
        })
    },

    applyGetUnformedEmployeesAction() {
      this.getUnformedEmployeesAction()
        .then(() => {
          this.$store.commit('SET_IS_UNFORMED_EMPLOYEES_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_UNFORMED_EMPLOYEES_LOADING', false)
        })
    },

    applyGetDirectOrdersWhoNeedRecommandationAction() {
      this.getDirectOrdersWhoNeedRecommandationAction()
        .then(() => {
          this.$store.commit(
            'SET_IS_DIRECT_ORDERS_WHO_NEED_RECOMMANDATION_LOADING',
            false,
          )
        })
        .catch(() => {
          this.$store.commit(
            'SET_IS_DIRECT_ORDERS_WHO_NEED_RECOMMANDATION_LOADING',
            false,
          )
        })
    },

    applyGetBusinessManagersListAction() {
      this.getBusinessManagersListAction()
        .then(() => {
          this.$store.commit('SET_IS_BUSINESS_MANAGERS_LIST_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_BUSINESS_MANAGERS_LIST_LOADING', false)
        })
    },

    applyGetBusinessManagersStatisticsAction() {
      this.getBusinessManagersListAction()
        .then(() => {
          this.$store.commit('SET_IS_BUSINESS_MANAGERS_LIST_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_BUSINESS_MANAGERS_LIST_LOADING', false)
        })
    },

    applyGetBusinessDeveloperListAction() {
      this.getBusinessDeveloperListAction()
        .then(() => {
          this.$store.commit('SET_IS_BUSINESS_DEVELOPER_LIST_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_BUSINESS_DEVELOPER_LIST_LOADING', false)
        })
    },

    applyGetARHListAction() {
      this.getAssistantRHListAction()
        .then(() => {
          this.$store.commit('SET_IS_ASSISTANT_RH_LIST_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_ASSISTANT_RH_LIST_LOADING', false)
        })
    },

    applyGetAdminOrderListAction() {
      this.getAdminOrderListAction()
        .then(() => {
          this.$store.commit('SET_IS_ADMIN_ORDER_LIST_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_ADMIN_ORDER_LIST_LOADING', false)
        })
    },

    applyGetNotificationListAction() {
      this.$store.commit('SET_IS_NOTIFICATION_LIST_LOADING', true)
      this.getNotificationsAction()
        .then(() => {
          this.$store.commit('SET_IS_NOTIFICATION_LIST_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_NOTIFICATION_LIST_LOADING', false)
        })
    },

    applyGetNotificationsListAction() {
      this.getPointFocalAction()
        .then(() => {
          this.$store.commit('SET_IS_POINTS_FOCAUX_LIST_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_POINTS_FOCAUX_LIST_LOADING', false)
        })
    },

    applyGetBusinessDeveloperStatisticsAction() {
      this.getBusinessDeveloperListAction()
        .then(() => {
          this.$store.commit('SET_IS_BUSINESS_DEVELOPER_LIST_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_BUSINESS_DEVELOPER_LIST_LOADING', false)
        })
    },

    // applyGetARHStatisticsAction() {
    //   this.getAssistantRHListAction()
    //     .then(() => {
    //       this.$store.commit("SET_IS_ASSISTANT_RH_LIST_LOADING", false);
    //     })
    //     .catch(() => {
    //       this.$store.commit("SET_IS_ASSISTANT_RH_LIST_LOADING", false);
    //     });
    // },

    applyGetAdminOrderStatisticsAction() {
      this.getBusinessDeveloperListAction()
        .then(() => {
          this.$store.commit('SET_IS_ADMIN_ORDER_LIST_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_ADMIN_ORDER_LIST_LOADING', false)
        })
    },

    applyGetCanceledOrdersAction() {
      this.getCanceledOrdersAction()
        .then(() => {
          this.$store.commit('SET_IS_CANCELED_ORDER_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_CANCELED_ORDER_LOADING', false)
        })
    },

    applyGetProPerServiceAndAdressAction() {
      this.getProPerServiceAndAdressAction()
        .then(() => {
          this.$store.commit('SET_IS_PRO_PER_ADDRESS__AND_SERVICE_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_PRO_PER_ADDRESS__AND_SERVICE_LOADING', false)
        })
    },

    applyGetProAmountPerServiceAction() {
      this.getProAmountPerServiceAction()
        .then(() => {
          this.$store.commit('SET_PRO_AMOUNT_PER_SERVICE_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_PRO_AMOUNT_PER_SERVICE_LOADING', false)
        })
    },

    applyGetServicesAction() {
      this.getServicesAction()
        .then(() => {
          this.$store.commit('SET_IS_SERVICES_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_SERVICES_LOADING', false)
        })
    },

    applyGetOrdersAction() {
      this.getOrdersAction()
        .then(() => {
          this.$store.commit('SET_IS_ORDERS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_ORDERS_LOADING', false)
        })
    },
    applyGetDirectPackagesAction() {
      this.getDirectPackagesAction()
        .then(response => {
          this.$store.commit('SET_IS_DIRECT_PACKAGES_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_DIRECT_PACKAGES_LOADING', false)
        })
    },
    applyGetDirectPackageOrdersAction() {
      this.getDirectPackageOrdersAction()
        .then(response => {
          this.$store.commit('SET_IS_DIRECT_PACKAGE_ORDERS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_DIRECT_PACKAGE_ORDERS_LOADING', false)
        })
    },

    applyGetProfessionalsAction() {
      this.getProfessionalsAction()
        .then(() => {
          this.$store.commit('SET_IS_PROFESSIONALS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_PROFESSIONALS_LOADING', false)
        })
    },

    applyGetCustomersAction() {
      this.getCustomersAction()
        .then(() => {
          this.$store.commit('SET_IS_CUSTOMERS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_CUSTOMERS_LOADING', false)
        })
    },

    applyGetCustomersWithoutPaginationAction() {
      this.getCustomersWithoutPaginationAction()
        .then(() => {
          this.$store.commit('SET_IS_CUSTOMERS_WITHOUT_PAGINATION_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_CUSTOMERS_WITHOUT_PAGINATION_LOADING', false)
        })
    },
    applyEmployeesWithoutPaginationAction() {
      this.getEmployeesWithoutPaginationAction()
        .then(() => {
          this.$store.commit('SET_IS_EMPLOYEES_WITHOUT_PAGINATION_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_EMPLOYEES_WITHOUT_PAGINATION_LOADING', false)
        })
    },
    applyProfessionalsWithoutPaginationAction() {
      this.getProfessionalsWithoutPaginationAction()
        .then(() => {
          this.$store.commit('SET_IS_PROFESSIONALS_WITHOUT_PAGINATION_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_PROFESSIONALS_WITHOUT_PAGINATION_LOADING', false)
        })
    },

    applyGetServicesWithoutPaginationAction() {
      this.getServicesWithoutPaginationAction()
        .then(() => {
          this.$store.commit('SET_IS_SERVICES_WITHOUT_PAGINATION_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_SERVICES_WITHOUT_PAGINATION_LOADING', false)
        })
    },

    applyGetSatisfiedOrderAction() {
      this.getSatisfiedOrderAction()
        .then(() => {
          this.$store.commit('SET_IS_SATISFIED_ORDER_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_SATISFIED_ORDER_LOADING', false)
        })
    },

    applyGetUnsatisfiedOrderAction() {
      this.getUnsatisfiedOrderAction()
        .then(() => {
          this.$store.commit('SET_IS_UNSATISFIED_ORDER_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_UNSATISFIED_ORDER_LOADING', false)
        })
    },

    applyGetProAmountAction() {
      this.getProAmountAction()
        .then(() => {
          this.$store.commit('SET_IS_PRO_AMOUNT_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_PRO_AMOUNT_LOADING', false)
        })
    },

    applyGetCommissionAction() {
      this.getCommissionAction()
        .then(() => {
          this.$store.commit('SET_IS_COMMISSION_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_COMMISSION_LOADING', false)
        })
    },

    applyGetCustomerConversionAction() {
      this.getCustomerConversionAction()
        .then(() => {
          this.$store.commit('SET_IS_CUSTOMERS_COMMISION_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_CUSTOMERS_COMMISION_LOADING', false)
        })
    },

    applyGetDateFinanceStatisticsAction() {
      this.getDateFinanceStatisticsAction()
        .then(() => {
          this.$store.commit('SET_IS_DATE_FINANCE_STATISTICS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_DATE_FINANCE_STATISTICS_LOADING', false)
        })
    },

    applyGetNumbersStatisticsAction() {
      this.getNumbersStatisticsAction()
        .then(() => {
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
    },
    applyGetRCStatisticsAction() {
      this.getRCStatisticsAction()
        .then(() => {
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
    },
    applyGetCAStatisticsAction() {
      this.getCAStatisticsAction()
        .then(() => {
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
    },
    applyGetCCStatisticsAction() {
      this.getCCStatisticsAction()
        .then(() => {
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
    },
    applyGetRHStatisticsAction() {
      this.getRHStatisticsAction()
        .then(() => {
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
    },
    applyGetARHStatisticsAction() {
      this.getARHStatisticsNumberAction()
        .then(() => {
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
    },

    applyGetTauxStatisticsAction() {
      this.getTauxStatisticsAction()
        .then(() => {
          this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
        })
    },

    applyGetTauxStatisticsRCAction() {
      this.getTauxStatisticsRCAction()
        .then(() => {
          this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
        })
    },

    applyGetTauxStatisticsCAAction() {
      this.getTauxStatisticsCAAction()
        .then(() => {
          this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
        })
    },
    applyGetTauxStatisticsCAByIdAction() {
      this.getTauxStatisticsCAByIdAction()
        .then(() => {
          this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
        })
    },
    applyGetTauxStatisticsARHByIdAction() {
      this.getTauxStatisticsARHByIdAction()
        .then(() => {
          this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
        })
    },
    applyGetTauxStatisticsCCAction() {
      this.getTauxStatisticsCCAction()
        .then(() => {
          this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
        })
    },

    applyGetTauxStatisticsRHAction() {
      this.getTauxStatisticsRHAction()
        .then(() => {
          this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
        })
    },
    applyGetTauxStatisticsARHAction() {
      this.getTauxStatisticsARHAction()
        .then(() => {
          this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
        })
    },

    applyGetMonthFinanceStatisticsAction() {
      this.getMonthFinanceStatisticsAction()
        .then(() => {
          this.$store.commit('SET_IS_MONTH_FINANCE_STATISTICS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_MONTH_FINANCE_STATISTICS_LOADING', false)
        })
    },

    applyGetCustomersInDeptAction() {
      this.getCustomersInDeptAction()
        .then(() => {
          this.$store.commit('SET_IS_CUSTOMERS_IN_DEPT_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_CUSTOMERS_IN_DEPT_LOADING', false)
        })
    },

    // Functions to apply Direct Actions
    applyGetDirectOrderAction() {
      this.getDirectOrdersAction()
        .then(() => {
          this.$store.commit('SET_IS_DIRECT_ORDERS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_DIRECT_ORDERS_LOADING', false)
        })
    },

    applyGetBusinessOrdersAction() {
      this.getBusinessOrdersAction()
        .then(() => {
          this.$store.commit('SET_IS_BUSINESS_ORDERS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_BUSINESS_ORDERS_LOADING', false)
        })
    },

    applyGetRecurringServicesAction() {
      this.getRecurringServicesAction()
        .then(() => {
          this.$store.commit('SET_IS_RECURRING_SERVICES_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_RECURRING_SERVICES_LOADING', false)
        })
    },

    applyGetMostRequestedRecurringServicesAction() {
      this.getMostRequestedRecurringServicesAction()
        .then(() => {
          this.$store.commit('SET_IS_MOST_REQUESTED_RECURRING_SERVICES_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_MOST_REQUESTED_RECURRING_SERVICES_LOADING', false)
        })
    },
    applyGetMostRequestedServicesAction() {
      this.getMostRequestedServicesAction()
        .then(() => {
          this.$store.commit('SET_IS_MOST_REQUESTED_SERVICES_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_MOST_REQUESTED_SERVICES_LOADING', false)
        })
    },

    applyGetEmployeesAction() {
      this.getEmployeesAction()
        .then(() => {
          this.$store.commit('SET_IS_EMPLOYEES_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_EMPLOYEES_LOADING', false)
        })
    },

    applyGetPayedAndUnpayedEmployeesAction() {
      this.getPayedAndUnpayedEmployeesAction()
        .then(() => {
          this.$store.commit('SET_IS_PAYED_AND_UNPAYED_EMPLOYEES_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_PAYED_AND_UNPAYED_EMPLOYEES_LOADING', false)
        })
    },

    applyGetPayedProsAction() {
      this.getPayedProsAction()
        .then(() => {
          this.$store.commit('SET_IS_PAYED_PROS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_PAYED_PROS_LOADING', false)
        })
    },

    applyGetUnpayedProsAction() {
      this.getUnpayedProsAction()
        .then(() => {
          this.$store.commit('SET_IS_UNPAYED_PROS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_UNPAYED_PROS_LOADING', false)
        })
    },

    applyGetEmployeesWalletWallet() {
      this.getEmployeesWalletAction()
        .then(() => {
          this.$store.commit('SET_IS_EMPLOYEE_WALLET_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_EMPLOYEE_WALLET_LOADING', false)
        })
    },

    applyGetEmployeesWalletsLogsWallet() {
      this.getEmployeesWalletsLogsAction()
        .then(() => {
          this.$store.commit('SET_IS_EMPLOYEES_WALLETS_LOGS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_EMPLOYEES_WALLETS_LOGS_LOADING', false)
        })
    },

    applyGetNotCompletedDirectOrdersAction() {
      this.getNotCompletedDirectOrdersAction()
        .then(() => {
          this.$store.commit('SET_IS_NOT_COMPLETED_DIRECT_ORDERS', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_NOT_COMPLETED_DIRECT_ORDERS', false)
        })
    },

    applyGetArchivedDirectOrdersAction() {
      this.getArchivedDirectOrdersAction()
        .then(() => {
          this.$store.commit('SET_ARCHIVED_DIRECT_ORDERS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_ARCHIVED_DIRECT_ORDERS_LOADING', false)
        })
    },

    applyGetArchivedBusinessOrdersAction() {
      this.getArchivedBusinessOrdersAction()
        .then(() => {
          this.$store.commit('SET_ARCHIVED_BUSINESS_ORDERS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_ARCHIVED_BUSINESS_ORDERS_LOADING', false)
        })
    },

    applyGetTransactionsAction() {
      this.getTransactionsAction()
        .then(response => {
          this.$store.commit('SET_IS_TRANSACTIONS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_TRANSACTIONS_LOADING', false)
        })
    },

    applyGetClientByPortefeuilleAction() {
      this.getClientByPortefeuilleAction()
        .then(response => {
          this.$store.commit('SET_IS_CLIENT_BY_PORTEFEUILLE_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_CLIENT_BY_PORTEFEUILLE_LOADING', false)
        })
    },
    applyGetActivityAction() {
      this.getActivityAction()
        .then(response => {
          this.$store.commit('SET_IS_ACTIVITY_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_ACTIVITY_LOADING', false)
        })
    },
    applyGetCustomersMeetAction() {
      this.getCustomersMeetAction()
        .then(response => {
          this.$store.commit('SET_IS_CUSTOMERS_MEET_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_CUSTOMERS_MEET_LOADING', false)
        })
    },
  },
}
</script>
