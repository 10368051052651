export default [
  {
    title: 'Tableau de bord',
    icon: 'HomeIcon',
    children: [
      {
        title: 'Général',
        route: 'dashboard-index',
        icon: 'GridIcon',
      },
      {
        title: 'Statistique',
        icon: 'BarChartIcon',
        children: [
          {
            title: 'Finance',
            route: 'dashboard-statistics-finance',
            icon: 'DollarSignIcon',
          },
          {
            title: 'Taux',
            route: 'dashboard-statistics-taux',
            icon: 'DollarSignIcon',
          },
          {
            title: 'Chiffres',
            route: 'dashboard-statistics-numbers',
            icon: 'CircleIcon',
          },
          {
            title: "Chargé d'affaire",
            route: 'dashboard-statistics-ca',
            icon: 'ActivityIcon',
          },

          {
            title: 'Ressource Humaine',
            route: 'dashboard-statistics-bdp',
            icon: 'ActivityIcon',
          },
          {
            title: 'Points focaux',
            route: 'points-focaux',
            icon: 'ActivityIcon',
          },
        ],
      },
    ],
  },
  {
    title: 'Commandes',
    icon: 'ShoppingBagIcon',
    route: 'admin-orders',
    children: [
      {
        title: 'Récurrentes',
        route: 'admin-orders-recurring',
        icon: 'RepeatIcon',

      },
      {
        title: 'Ponctuelles',
        route: 'admin-orders-punctual',
        icon: 'ClockIcon',
      },
      {
        title: 'Business',
        route: 'admin-orders-business',
        icon: 'TagIcon',
      },
    ],
  },
  {
    title: 'Propositions',
    route: 'admin-work-providers-recommandations',
    icon: 'UsersIcon',
  },
  {
    title: 'Prestataires',
    icon: 'UserCheckIcon',
    children: [
      {
        title: 'Employés',
        route: 'admin-work-providers-employees',
        icon: 'UsersIcon',
      },

      {
        title: 'Professionnels',
        route: 'admin-work-providers-professionals',
        icon: 'UsersIcon',
      },
    ],
  },
  {
    title: 'Finance',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'Paiements des clients',
        route: 'admin-orders-recurring-payment-history',
        icon: 'DollarSignIcon',
      },
      {
        title: 'Paiements Business',
        route: 'admin-orders-business-recurring-payment-history',
        icon: 'DollarSignIcon',
      },
      {
        title: 'Fiche de paie des employés',
        route: 'admin-employees-payslip',
        icon: 'FileIcon',
      },
      {
        title: 'Portefeuille des employés',
        route: 'admin-employees-wallet-list',
        icon: 'CreditCardIcon',
      },
      {
        title: 'Employés payé et non payé',
        route: 'admin-employees-payed-and-unpayed-list',
        icon: 'CreditCardIcon',
      },
      {
        title: 'Transactions',
        route: 'admin-clients-transactions',
        icon: 'CreditCardIcon',
      },
      {
        title: 'Paiement par portefeuille',
        route: 'admin-clients-payment-by-portefeuille-list',
        icon: 'CreditCardIcon',
      },

    ],
  },
  {
    title: 'Prospects',
    route: 'admin-orders-recurrent-unsuccessful',
    icon: 'UserIcon',
  },
  {
    title: 'Points Focaux',
    route: 'admin-work-providers-points-focaux',
    icon: 'MapPinIcon',
  },
  {
    title: 'Clients',
    icon: 'UsersIcon',
    route: 'admin-customers',
  },
  {
    title: 'Notifications',
    icon: 'SendIcon',
    route: 'notifications',
  },
  {
    title: 'Blog',
    icon: 'FeatherIcon',
    route: 'blog',
  },
  {
    title: 'Services',
    icon: 'BriefcaseIcon',
    children: [
      {
        title: 'Ponctuels',
        route: 'admin-services',
        icon: 'ClockIcon',
      },
      {
        title: 'Récurrents',
        route: 'admin-direct-services',
        icon: 'RepeatIcon',
      },
    ],
  },
  {
    title: 'Admin & Rôles',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Admins',
        route: 'admin-users',
        icon: 'UsersIcon',
      },
      {
        title: 'Rôles',
        route: 'admin-access',
        icon: 'CheckSquareIcon',
      },
    ],
  },
]
