<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <!--<dark-Toggler class="d-none d-lg-block" />-->
      <b-card-text class="font-medium-2"> Espace d'administration </b-card-text>
    </div>

    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <notification-dropdown />

      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ getUser.first_name }} {{ getUser.last_name }}
            </p>
            <span class="user-status">{{ getUser.role.name }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :text="
              avatarText(`${getUser.first_name} ` + ` ${getUser.last_name}`)
            "
            :src="getUser.profile_image"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          :to="{ name: 'account-details' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profil</span>
        </b-dropdown-item>

        <b-dropdown-item
          :to="{ name: 'activity' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="ActivityIcon"
            class="mr-50"
          />
          <span>Activités</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="applyLogoutAction"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Déconnexion</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BCardText,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import { avatarText } from '@core/utils/filter'
import NotificationDropdown from './NotificationDropdown.vue'

// import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BCardText,
    NotificationDropdown,
    // Navbar Components
    // DarkToggler,
  },

  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      avatarText,
    }
  },

  computed: {
    ...mapGetters('auth', ['getUser']),
  },
  created() {
    const token = localStorage.getItem('token')
    const user = JSON.parse(localStorage.getItem('user'))
    this.$store.commit('auth/SET_USER', user)
    this.$store.commit('auth/SET_TOKEN', token)
  },
  methods: {
    ...mapActions('auth', ['logoutAction']),
    applyLogoutAction() {
      this.$swal({
        title: 'Déconnexion',
        text: 'Êtes-vous sûr de vouloir vous déconnecter?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        // eslint-disable-next-line no-return-await
        preConfirm: async () => await this.logoutAction(),
      }).then(result => {
        if (result.value && result.value.status === 200) {
          this.$router.push({ name: 'login' })
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
